import { useRef, useState, useEffect } from "react";
import { getBrands, getCampaigns, getJourneyGroups, downloadEmails } from "../../api/axios"
import Header from "../../components/Header"
import EmailsTable from "./EmailsTable"
import useAuth from "../../hooks/useAuth";

const Emails = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  

  const idBrandRef = useRef();
  const [idBrand, setIdBrand] = useState(0);
  const [idBrands, setIdBrands] = useState([]);

  const idCampaignRef = useRef();
  const [idCampaign, setIdCampaign] = useState(0);
  const [idCampaigns, setIdCampaigns] = useState([]);

  const idJourneyGroupRef = useRef();
  const [idJourneyGroup, setIdJourneyGroup] = useState(0);
  const [idJourneyGroups, setIdJourneyGroups] = useState([]);

  const subjectRef = useRef();
  const [subject, setSubject] = useState('');
  
  const dateFromRef = useRef();
  const [dateFrom, setDateFrom] = useState('');

  const dateUntilRef = useRef();
  const [dateUntil, setDateUntil] = useState('');

  const { auth } = useAuth();

  useEffect(() => {

    const fetchData = async () => {
      
      setIsLoading(true);
      
      try {
        
        let data = await getBrands({ auth });
        setIdBrands(data.results);

        data = await getCampaigns({ auth });
        setIdCampaigns(data.results);

        data = await getJourneyGroups({ auth });
        setIdJourneyGroups(data.results);

        setError(null);

      } catch (error) {

        setError(error);
        setIdBrands(null);
        setIdCampaigns(null);
        setIdJourneyGroups(null);

      }

      setIsLoading(false);

    };

    fetchData();
    
  }, [auth]);


  
  const handleSubmit = (event) => {

    event.preventDefault();

    setIdBrand(event.target.idBrand.value);
    setIdCampaign(event.target.idCampaign.value);
    setIdJourneyGroup(event.target.idJourneyGroup.value);
    setSubject(event.target.subject.value);
    setDateFrom(event.target.dateFrom.value);
    setDateUntil(event.target.dateUntil.value);
    
  }

const handleDownload = async () => {

  try {
    setIdBrand(idBrandRef.current.value);
    setIdCampaign(idCampaignRef.current.value);
    setIdJourneyGroup(idJourneyGroupRef.current.value);
    setSubject(subjectRef.current.value);
    setDateFrom(dateFromRef.current.value);
    setDateUntil(dateUntilRef.current.value);

    const params = {
      params: {
        idBrand,
        idCampaign,
        idJourneyGroup,
        subject,
        dateFrom,
        dateUntil,
      },
    }

    const blob = await downloadEmails({ props: params, auth })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.download = `emails-export-${new Date().toISOString().split("T")[0]}.xlsx`

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error("Error al descargar:", error)
    alert("Error al descargar el archivo. Por favor intente nuevamente.")
  } finally {
    
  }
}


  return (

    <>

      <Header />
              
        <div className="content">

        <h2 className="page_title">Envíos</h2>

          <div className="table_div">

            <div className="filters_div">

              <form action="" onSubmit={handleSubmit}>

                <ul>

                  <li>
                    <label htmlFor="idBrand">
                      <span>Marca</span>
                      <select 
                        ref={idBrandRef}
                        id="idBrand" 
                        name="idBrand" 
                      >
                        <option value="0"></option>
                        
                      {!isLoading && idBrands && idBrands?.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                      
                      </select>
                    </label> 
                    
                  </li>
                  <li>
                  <label htmlFor="dateFrom">
                  <span>Fecha Desde</span>
                  <input 
                      ref={dateFromRef}
                      name="dateFrom" 
                      type="date"
                      placeholder="YYYY-MM-DD"    
                      className="w--110 center"
                  />
                  </label> 
              </li>

              <li>
                  <label htmlFor="dateUntil">
                  <span>Fecha Hasta</span>
                  <input 
                      ref={dateUntilRef}
                      name="dateUntil" 
                      type="date"
                      placeholder="YYYY-MM-DD"
                      className="w--110 center"
                  />
                  </label> 
              </li>
                  <li>
                    <label htmlFor="idCampaign">
                      <span>Campaña</span>
                      <select 
                        ref={idCampaignRef}
                        id="idCampaign" 
                        name="idCampaign" 
                      >
                        <option value="0"></option>
                        
                      {!isLoading && idCampaigns && idCampaigns?.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.year} | {option.name}
                        </option>
                      ))}
                      
                      </select>
                    </label> 
                  </li>
                  <li>
                    <label htmlFor="idJourneyGroup">
                      <span>Journey Group</span>
                      <select 
                        ref={idJourneyGroupRef}
                        id="idJourneyGroup" 
                        name="idJourneyGroup" 
                      >
                        <option value="0"></option>
                        
                      {!isLoading && idJourneyGroups && idJourneyGroups?.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                      
                      </select>
                    </label> 
                  </li>
                  
                  <li>
                    <label htmlFor="subject">
                      <span>Asunto</span>
                      <input 
                        ref={subjectRef}
                        name="subject" 
                        type="text"                         
                      />
                    </label> 
                  </li>
                  <li>
                    <button className="button-outline" type="submit">Buscar</button>
                  </li>
                  <li>
                    <button className="button-outline"  type="button" onClick={handleDownload}>Descargar</button>
                  </li>
                </ul>

              </form>

            </div>

            <EmailsTable params={ { idBrand, idCampaign, idJourneyGroup, subject, dateFrom, dateUntil } } />

          </div>

        </div>

    </>
    
    )
}

export default Emails