import { Link } from "react-router-dom"
import { getEmails } from "../../api/axios"
import { useState, useEffect } from "react";
import { FaCode } from "react-icons/fa";
import { day, perc, nf } from "../../utilities/Utils"
import useAuth from "../../hooks/useAuth";

const EmailsTable = (props) => {

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [emails, setEmails] = useState([]);

  const { auth } = useAuth();

  useEffect(() => {

    const fetchData = async () => {
        
      setIsLoading(true);
      
      try {
        
        const data = await getEmails({props, auth});

        // const blob = await data.blob();
        // const url = window.URL.createObjectURL(blob);

        // const a = document.createElement('a');
        // a.href = url;
        // a.download = 'archivo.xlsx';
        // document.body.appendChild(a);
        // a.click();
        // a.remove();

        // window.URL.revokeObjectURL(url);

        setEmails(data.results);
        setError(null);

      } catch (error) {

        console.log(error);

        setError(error);
        setEmails(null);

      }

      setIsLoading(false);

    };


    fetchData();
      
  }, [props, auth]);


  return (

    <>
          
      <table className="table">
                      
        <thead>
            <tr>
                <td>#</td> 
                <td>Activo</td> 
                <td>Remitente / Lista  de destintarios</td> 
                <td>Campaña / Nombre / Asunto</td> 
                <td nowrap="nowrap">Fecha Envío</td> 
                <td>Enviados</td> 
                <td className="c">Personas<br />alcanzadas</td>
                <td>Desuscriptos</td> 
                <td>Aperturas</td> 
                <td>Clicks</td> 
                <td>CTOR</td> 
            </tr>
        </thead>
        <tbody>
          {isLoading ? (
              <tr>
                  <td colSpan="14" className="c loading">Cargando...</td>
              </tr>
          ) : !emails?.length ? (
              <tr>
                  <td colSpan="14" className="c loading">No se encontraron resultados</td>
              </tr>
          ) : (
              <>
                  {emails.map((item) => (
                      <tr key={item.id}>
                          <td className="c light">
                              <Link className="vinculo" to={`/emails/edit/${item.id}`}>
                                  {item.id}
                              </Link>
                          </td>
                          <td>
                              <input checked={item.active === "1"} type="checkbox" className="switch" readOnly />
                          </td>
                          <td>
                              {item.sender_name}
                              <br />
                              <span className="light">{item.sender_email}</span>
                              <br />
                              <span className="light">{item.contact_list_name}</span>
                          </td>
                          <td>
                              <span className="light">
                                  {item.campaign_year} - {item.campaign_name}
                                  <br />
                                  {item.name}
                              </span>
                              <Link className="vinculo block" to={`/emails/${item.id}`}>
                                  {item.subject}
                              </Link>
                          </td>
                          <td className="c">{day(item.date_sent_from)}</td>
                          <td className="c">
                              <span className="valor">{nf(item.sent)}</span>
                          </td>
                          <td className="c">
                              <span className="valor">{nf(item.distinct_contact_count)}</span>
                          </td>
                          <td className="c">
                              <span className="valor baja">{nf(item.unsubscribed)}</span>
                              <br />
                              <span className="light perc">{perc(item.sent, item.unsubscribed)}</span>
                          </td>
                          <td className="c">
                              <span className="valor">{nf(item.opened)}</span>
                              <br />
                              <span className="light perc">{perc(item.sent, item.opened)}</span>
                          </td>
                          <td className="c">
                              <span className="valor">{nf(item.clicks)}</span>
                              <br />
                              <span className="light perc">{perc(item.sent, item.clicks)}</span>
                          </td>
                          <td className="c">
                              <span className="valor">{item.ctor}%</span>
                          </td>
                      </tr>
                  ))}
                  {/* Fila de totales */}
                  {emails.length > 0 && (
                      <tr className="totales">
                          <td colSpan="5" className="c light">Totales</td>
                          <td className="c">{nf(emails.reduce((acc, item) => acc + parseInt(item.sent || 0, 10), 0))}</td>
                          <td className="c">{nf(emails.reduce((acc, item) => acc + parseInt(item.distinct_contact_count || 0, 10), 0))}</td>
                          <td className="c">
                              <span className="valor baja">
                                  {nf(emails.reduce((acc, item) => acc + parseInt(item.unsubscribed || 0, 10), 0))}
                              </span>
                              <br />
                              <span className="light perc">
                                  {(() => {
                                      const totalSent = emails.reduce((acc, item) => acc + parseInt(item.sent || 0, 10), 0);
                                      return totalSent > 0
                                          ? perc(totalSent, emails.reduce((acc, item) => acc + parseInt(item.unsubscribed || 0, 10), 0))
                                          : "0%";
                                  })()}
                              </span>
                          </td>
                          <td className="c">
                              <span className="valor">
                                  {nf(emails.reduce((acc, item) => acc + parseInt(item.opened || 0, 10), 0))}
                              </span>
                              <br />
                              <span className="light perc">
                                  {(() => {
                                      const totalSent = emails.reduce((acc, item) => acc + parseInt(item.sent || 0, 10), 0);
                                      return totalSent > 0
                                          ? perc(totalSent, emails.reduce((acc, item) => acc + parseInt(item.opened || 0, 10), 0))
                                          : "0%";
                                  })()}
                              </span>
                          </td>
                          <td className="c">
                              <span className="valor">
                                  {nf(emails.reduce((acc, item) => acc + parseInt(item.clicks || 0, 10), 0))}
                              </span>
                              <br />
                              <span className="light perc">
                                  {(() => {
                                      const totalSent = emails.reduce((acc, item) => acc + parseInt(item.sent || 0, 10), 0);
                                      return totalSent > 0
                                          ? perc(totalSent, emails.reduce((acc, item) => acc + parseInt(item.clicks || 0, 10), 0))
                                          : "0%";
                                  })()}
                              </span>
                          </td>
                          <td className="c">
                            <span className="valor">

                              {(() => {
                                const totalSent = emails.reduce((acc, item) => acc + parseInt(item.sent || 0, 10), 0);
                                return totalSent > 0
                                ? (
                                  emails.reduce((acc, item) => acc + parseFloat(item.ctor || 0) * parseInt(item.sent || 0, 10), 0) /
                                  totalSent
                                ).toFixed(2) + "%"
                                : "0%";
                              })()}
                              </span>
                          </td>
                      </tr>
                  )}
              </>
          )}
      </tbody>



      </table>
    </>
    
    )
}

export default EmailsTable